import { useState } from 'react'

function useCopyClipboard() {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = (copyValue) => {
    if (!navigator.clipboard) {
      // Clipboard API not supported, fallback to older method
      const textArea = document.createElement('textarea')
      textArea.value = copyValue
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        setCopied(successful)
      } catch (err) {
        setCopied(false)
      }

      document.body.removeChild(textArea)
      return
    }

    navigator.clipboard
      .writeText(copyValue)
      .then(() => {
        console.log('copy successfull')
        setCopied(true)
      })
      .catch((_) => {
        setCopied(false)
      })
  }

  return { copied, copyToClipboard }
}

export default useCopyClipboard
