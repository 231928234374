import '.././Withdrawal/Withdrawal.css'
import { TfiClose } from 'react-icons/tfi'

const NoWithdrawal = ({ onClose, history }) => {
  return (
    <div className="withdrawal_con">
      <div className="withdraw_card">
        <div className="header">
          <div className="header_text">Transaction Info</div>
          <TfiClose className="close_btn" onClick={onClose} />
        </div>

        <div style={{ padding: '1rem 2rem' }}>
          <div className="history_user_subtitle">Transaction Details</div>
          <div className="history_user_entries">
            <div> Transaction Id:</div> <div>{history._id}</div>
          </div>

          <div className="history_user_entries">
            <div> Transaction Date:</div>{' '}
            <div>
              {new Date(history.createdAt).toLocaleDateString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}
            </div>
          </div>

          <div className="history_user_entries">
            <div> Transaction Type:</div> <div>{history.type}</div>
          </div>

          <div className="history_user_entries">
            <div> Transaction Status:</div> <div>{history.status}</div>
          </div>

          <div className="history_user_entries">
            <div>
              {' '}
              {history.type.toLowerCase() === 'withdrawal'
                ? 'Amount Requested'
                : 'Transaction Amount'}
              :
            </div>{' '}
            <div>${history.amount}</div>
          </div>

          {history.type.toLowerCase() === 'withdrawal' && (
            <div className="history_user_entries">
              <div> Amount Received:</div> <div>${history.received}</div>
            </div>
          )}

          <div className="history_user_entries">
            <div> Additional Info:</div> <div>{history.note || '---'}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoWithdrawal
