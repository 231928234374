import React, { useContext, useState } from 'react'
import './Market.css'
import { UserContext } from '../../../context/UserContext'
import { MdBarChart } from 'react-icons/md'
import { FaShop } from 'react-icons/fa6'
import Neflix from '../../../assets/stocks/netflix.png'
import Microsoft from '../../../assets/stocks/microsoft.png'
import Nvidia from '../../../assets/stocks/nvidia.png'
import Meta from '../../../assets/stocks/meta.png'
import Amazon from '../../../assets/stocks/social.png'
import Apple from '../../../assets/stocks/apple.png'
import FedEx from '../../../assets/stocks/fedex.png'

const Calc = () => {
  const { user, totalDeposit, totalWithdraw } = useContext(UserContext)
  const [activetab, setActivetab] = useState('closed')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })

  return (
    <>
      <div className="dash__main order__con">
        <div className="calc__con">
          <div className="total__amount">
            <span>Total Deposit: {formatter.format(totalDeposit)} </span>
            <span>Total Withdraw: {formatter.format(totalWithdraw)}</span>
          </div>
          <div className="order__tab">
            <div className="tab__col first__tab">
              <div className="tab__button asset__button">
                <FaShop className="asset_market" />
              </div>
              <h4>ASSET MARKET</h4>
            </div>
            <div className="tab__col second__tab">
              <div className="tab__button trading__button">
                <MdBarChart className="trading__bar" />
              </div>
              <h4>TRADING ROOM</h4>
            </div>
          </div>

          <div className="order__container">
            <div className="order__container_tab">
              <div
                onClick={() => setActivetab('closed')}
                className={`order_close ${
                  activetab === 'closed' && 'order_selected'
                }`}
              >
                closed
              </div>
              <div
                onClick={() => setActivetab('active')}
                className={`order_active ${
                  activetab === 'active' && 'order_selected'
                }`}
              >
                active
              </div>
            </div>

            {user?.admin && (
              <>
                {activetab === 'closed' ? (
                  <div className="order_book_content">
                    <Stock
                      symbolUrl={Neflix}
                      price={370.05}
                      content="BUY 670.05 NFLX"
                      className="green_content"
                    />

                    <Stock
                      symbolUrl={Microsoft}
                      price={1250.41}
                      content="BUY 442.41 MSFT"
                      className="green_content"
                    />

                    <Stock
                      symbolUrl={Meta}
                      price={1200.02}
                      content="BUY 503.02 META"
                      className="green_content"
                    />

                    <Stock
                      symbolUrl={Nvidia}
                      price={131.25}
                      content="BUY 131.25 NVDA"
                      className="green_content"
                    />

                    <Stock
                      symbolUrl={Amazon}
                      price={183.46}
                      content="BUY 183.46  AMZN"
                      className="red_content"
                    />

                    <Stock
                      symbolUrl={FedEx}
                      price={210.25}
                      content="BUY 1722.14 FDX"
                      className="red_content"
                    />

                    <Stock
                      symbolUrl={Apple}
                      price={109.93}
                      content="BUY 209.93 AAPL"
                      className="green_content"
                    />
                  </div>
                ) : (
                  <div className="order_book_content">
                    <Stock
                      symbolUrl={Neflix}
                      price={1672.34}
                      content="BUY 1672.34 NFLX"
                      className="green_content"
                    />

                    <Stock
                      symbolUrl={Nvidia}
                      price={620.3}
                      content="BUY 620.30 NVDA"
                      className="red_content"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Calc

const Stock = ({ symbolUrl, price, content, className }) => {
  const formattter = new Intl.NumberFormat()
  const currentDate = new Date()
  const currentMonth = currentDate.toLocaleString('default', { month: 'short' })
  const currentDay = currentDate.getDate()

  return (
    <div className="trade_item">
      <div className="trade_item_group">
        <div className="date">
          <div>{currentMonth}</div>
          <div>{currentDay}</div>
        </div>
        <div className="symbol">
          <img src={symbolUrl} alt="stock" className="stock_image" />
        </div>
        <div className="trade_item_content">{content}</div>
      </div>
      <div className={`details ${className}`}>
        <div>
          {className === 'red_content' ? '-' : ''} ${formattter.format(price)}
        </div>
      </div>
    </div>
  )
}
