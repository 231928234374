import { Route, Routes } from 'react-router-dom'
import './App.css'
import Dashboard from './pages/Dashboard/Dashboard'
import Profile from './pages/Profile/Profile'
import NavUser from './components/NavUser/NavUser'
import Sidebar from './components/Dashboard/Sidebar/Sidebar'
import Market from './components/Dashboard/Main/Market'
import NewsPage from './components/Dashboard/Main/News'
import UserFooter from './components/Footer/UserFooter'
import { useContext, useEffect, useState } from 'react'
import Widget from './components/Dashboard/Widget/Widget'
import Calc from './components/Dashboard/Main/Calc'
import Home from './pages/LandingPage/Home'
import About from './pages/About/About'
import HomeContent from './pages/LandingPage/HomeContent'
import Blog from './pages/Blog/Blog'
import Testimonials from './pages/Testimonials/Testimonials'
import Faqs from './pages/Faqs/Faqs'
import Signin from './pages/Auth/Signin'
import Signup from './pages/Auth/Signup'
import ForgotPassword from './pages/Auth/ForgotPassword'

import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md'
import { UserContext } from './context/UserContext'
import { useCookies } from 'react-cookie'
import Terms from './components/Terms/Terms'
import Admin from './pages/Admin/Admin'
import History from './pages/History/History'
import Privacy from './components/Privacy/Privacy'
import CopyTrading from './pages/CopyTrading/CopyTrading'
import CopyTradingAdmin from './pages/Admin/CopyTradingAdmin/CopyTradingAdmin'

function App() {
  const [cookies] = useCookies(['user'])
  const { getUser, user, widName, getDashboard } = useContext(UserContext)

  const [loading, setLoading] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const checkAuth = async () => {
      if (cookies) {
        if (cookies?.userToken) {
          setLoading(true)
          await getUser()
          await getDashboard()
        }
      } else {
        setLoading(false)
      }
    }

    if (!user) {
      checkAuth()
    }
  }, [cookies, getUser, user, getDashboard])

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const [showProfile, setShowProfile] = useState(false)
  const [showAccountList, setShowAccountList] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showBalance, setShowBalance] = useState(false)
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showNoWithdrawal, setShowNoWithdrawal] = useState(false)
  const [showRequestWithdrawal, setShowRequestWithdrawal] = useState(false)
  const [isWidget, setIsWidget] = useState(false)

  const handleWidget = () => {
    setIsWidget(!isWidget)
  }

  const closeProfile = () => {
    setShowProfile(false)
  }
  const openProfile = () => {
    setShowProfile(true)
  }

  return (
    <>
      {!loading ? (
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />

          <Route path="/" element={<Home />}>
            <Route path="/" element={<HomeContent />} />
            <Route path="about" element={<About />} />
            <Route path="blog" element={<Blog />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="testimonials" element={<Testimonials />} />
            <Route path="faqs" element={<Faqs />} />
            <Route path="*" element={<Signin />} />
          </Route>
        </Routes>
      ) : (
        <>
          <div className="userAuth">
            <NavUser
              openProfile={openProfile}
              showProfile={showProfile}
              setShowAccountList={setShowAccountList}
              setShowNotifications={setShowNotifications}
              showNotifications={showNotifications}
              showAccountList={showAccountList}
              showBalance={showBalance}
              setShowNoWithdrawal={setShowNoWithdrawal}
              showNoWithdrawal={showNoWithdrawal}
              setShowBalance={setShowBalance}
              closeProfile={closeProfile}
              showRequestWithdrawal={showRequestWithdrawal}
              setShowRequestWithdrawal={setShowRequestWithdrawal}
              showTransferModal={showTransferModal}
              setShowTransferModal={setShowTransferModal}
            />
            <div className="dash_body">
              <div
                className="dash__con"
                onClick={() => {
                  closeProfile()
                  setShowAccountList(false)
                  setShowNotifications(false)
                  setShowBalance(false)
                }}
              >
                <aside className="dash__side">
                  <Sidebar handleWidget={handleWidget} />
                </aside>

                {isWidget ? (
                  <div
                    className={`${
                      isWidget
                        ? 'dash__widget dash__mobile__widget'
                        : 'notWidget'
                    }`}
                  >
                    <div
                      className={`${
                        isWidget ? 'dash__widget__right' : 'notWidget'
                      }`}
                    >
                      <Widget widName={widName} isWidget={isWidget} />
                    </div>
                    <div className="dash__widget__left">
                      <div
                        className="dash__widget__left__icon"
                        onClick={() => setIsWidget(false)}
                      >
                        <MdOutlineKeyboardDoubleArrowLeft size={15} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <div
                        className="dash__widget__left__icon"
                        onClick={() => setIsWidget(true)}
                      >
                        <MdOutlineKeyboardDoubleArrowRight size={15} />
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={`${isWidget ? 'dash__rest' : 'dash__rest__full'}`}
                >
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route
                      path="/admin"
                      element={<Admin showTransferModal={showTransferModal} />}
                    />
                    <Route
                      path="/admin/copytrading"
                      element={<CopyTradingAdmin />}
                    />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/orderbook" element={<Calc />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/market" element={<Market />} />
                    <Route path="/calc" element={<Calc />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/copytrading" element={<CopyTrading />} />
                    <Route path="*" element={<Calc />} />
                  </Routes>
                </div>
              </div>
            </div>
            <UserFooter
              closeProfile={closeProfile}
              setShowAccountList={setShowAccountList}
              setShowNotifications={setShowNotifications}
              setShowBalance={setShowBalance}
            />
          </div>
        </>
      )}
    </>
  )
}

export default App
