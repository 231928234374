import axios from 'axios'
import { Cookies } from 'react-cookie'

const cookies = new Cookies()

const client = axios.create({
  // baseURL: process.env.BASE_BACKEND_URL || 'http://localhost:7000',
  baseURL: 'https://bidvest-backend.onrender.com',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookies.get('userToken')}`,
  },
})

export const serverBaseURL = 'https://bidvest-backend.onrender.com'

export default client
