import '../Withdrawal/Withdrawal.css'
import './Status.css'

const SuccessModal = ({ onClickPrimaryBtn, message, primaryBtnText }) => {
  return (
    <div className="withdrawal_con">
      <div className="withdraw_card">
        <section className="status_icon">
          <div className="status_icon_wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 12.75 6 6 9-13.5"
              />
            </svg>
          </div>
        </section>
        <section className="status_message">
          <div className="status_message_label">Success</div>
          <div className="status_message_desc">{message}</div>
        </section>

        <section className="status_buttons">
          <button
            className="status_buttons_primary"
            onClick={onClickPrimaryBtn}
          >
            {primaryBtnText}
          </button>
        </section>
      </div>
    </div>
  )
}

export default SuccessModal
