import React, { useContext, useRef, useState, useEffect } from 'react'
import { FaGalacticSenate, FaRegCopy } from 'react-icons/fa'
import toast from 'react-hot-toast'
import { TfiClose } from 'react-icons/tfi'
import { walletaddress } from '../../../context/WalletAddress'
import useCopyClipboard from '../../../hooks/useCopyClipboard'
import Countdown from 'react-countdown'
import client from '../../../client'
import { useCookies } from 'react-cookie'
import './Transfer.css'

const Transfer = ({ setShowTransferModal }) => {
  const { address } = useContext(walletaddress)
  const { copied, copyToClipboard } = useCopyClipboard()
  const [isLoading, setIsLoading] = useState()
  const [message, setMessage] = useState('')
  const [cookies] = useCookies(['user'])

  const [file, setFile] = useState(null)

  const fileInputRef = useRef(null)

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Time's up!</span>
    } else {
      // Render the countdown
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      )
    }
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0])
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    setIsLoading(true)

    if (file) {
      // You can now send 'file' to the backend
      console.log('File to upload:', file)
      // Implement your file upload logic here

      const formData = new FormData()
      formData.append('payment', file)
      formData.append('userId', cookies?.userData?._id)

      try {
        const response = await client.post(
          '/pent/user/paymentProof',
          formData,
          {
            headers: {
              Authorization: `Bearer ${cookies.userToken}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (response.data) {
          toast.success('payment uploaded successfully')
          setIsLoading(false)
          setShowTransferModal(false)
        } else {
          toast.error('Failed to upload file')
        }
      } catch (error) {
        console.log('error', error)
        toast.error('An error occurred while uploading the file')
        setIsLoading(false)
      }
    }
  }

  const { currentSymbol, currentPayPrice } = useContext(walletaddress)
  return (
    <>
      <div className="transfer_con">
        <div className="transfer_card">
          <form onSubmit={handleSubmit} className="transfer_content">
            <TfiClose
              className="transfer_close_btn"
              onClick={() => setShowTransferModal(false)}
            />
            <div className="transfer_header">
              <h1>
                send {Number(currentPayPrice).toPrecision(3)} {currentSymbol}
              </h1>

              <div>
                <p>To the wallet address below or scan the qr</p>
                <p>code with your wallet app</p>
              </div>

              <div className="transfer_warning">
                <p>
                  please send only{' '}
                  {currentSymbol.toLowerCase() !== 'usdt'
                    ? currentSymbol.toLowerCase()
                    : 'Tether Trc20'}{' '}
                  to this address, sending any other
                </p>
                <p>token may result in parmanent loss</p>
              </div>
            </div>

            <div className="transfer_wallet info">
              <FaRegCopy
                onClick={() => {
                  copyToClipboard(address)
                  toast.success('Copied!')
                }}
                className="copy_icon"
              />
              <p>{address}</p>
            </div>

            <div className="transfer_tag info">
              <span>TAG</span>
              <p>Always confirm address before sending</p>
            </div>

            <div></div>

            <div className="await_payment">
              <Countdown
                date={Date.now() + 60 * 60 * 1000} // Set the countdown time to 1 hour from now
                renderer={renderer}
                onComplete={() => setShowTransferModal(false)}
              />
              <p>Awaiting Payment</p>
            </div>

            <input
              ref={fileInputRef}
              className="transfer_upload"
              type="file"
              onChange={handleFileChange}
            />

            <button
              type="submit"
              disabled={!file}
              className={`transfer_payment_btn ${
                file ? 'transfer_active' : 'transfer_disable'
              }`}
            >
              {isLoading ? 'loading....' : 'upload payment Proof'}
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default Transfer
