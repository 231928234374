import client from '../client'
import { createContext, useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [dash, setDash] = useState(null)
  const [token, setToken] = useState(null)
  const [cookies] = useCookies(['user'])
  const [widName, setWidName] = useState('market')
  const [userData] = useState(null)
  const [coins, setCoins] = useState(null)
  const [loader, setLoader] = useState(true)
  const [allUsers, setAllUsers] = useState(null)
  const [triggerFetchHistory, setTriggerFetchHistory] = useState(false)
  const [totalDeposit, setTotalDeposit] = useState(0)
  const [totalWithdraw, setTotalWithdraw] = useState(0)

  const getUser = async () => {
    await client
      .get('/pent/user/getUser', {
        headers: {
          Authorization: `Bearer ${cookies.userToken}`,
        },
      })
      .then(function (response) {
        setUser(response.data)
      })
      .catch((err) => {
        const cookies = new Cookies()
        cookies.remove('userData')
        cookies.remove('userToken')
        window.location.href = '/signin'
        console.log(err)
      })
  }

  const getDashboard = async () => {
    await client
      .get('/pent/user/getData', {
        headers: {
          Authorization: `Bearer ${cookies.userToken}`,
        },
      })
      .then(function (response) {
        setDash(response.data)
        // console.log(response.data)
      })
      .catch((err) => {
        const cookies = new Cookies()
        cookies.remove('userData')
        cookies.remove('userToken')
        window.location.href = '/signin'
        console.log(err)
      })
  }

  const getData = async () => {
    setLoader(true)
    await client
      .get('/pent/data/coins', {
        headers: {
          Authorization: `Bearer ${cookies.userToken}`,
        },
      })
      .then((res) => {
        setCoins(res.data.data)
        // console.log(res.data.data)
        setLoader(false)
      })
      .catch((err) => {
        setLoader(true)
        const cookies = new Cookies()
        cookies.remove('userData')
        cookies.remove('userToken')
        window.location.href = '/signin'
        console.log(err)
      })
  }

  const getAllUser = async () => {
    setLoader(true)
    await client
      .get('/pent/admin/getAllUser', {
        headers: {
          Authorization: `Bearer ${cookies.userToken}`,
        },
      })
      .then((res) => {
        setAllUsers(res.data)
        // console.log(res.data)
        console.log('all users')
        setLoader(false)
      })
      .catch((err) => {
        setLoader(false)
        const cookies = new Cookies()
        cookies.remove('userData')
        cookies.remove('userToken')
        window.location.href = '/signin'
        console.log(err)
      })
  }

  useEffect(() => {
    const getTotalAmount = async () => {
      let totalDeposit = 0
      let totalWithdraw = 0

      const depositRes = await client.get(
        '/pent/user/getHistory?type=Deposit',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.userToken}`,
          },
        },
      )

      depositRes.data?.data?.map((history) => {
        if (history.status === 'Paid') {
          totalDeposit += history.amount
        }
      })

      setTotalDeposit(totalDeposit)

      const withdrawRes = await client.get(
        '/pent/user/getHistory?type=Withdrawal',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.userToken}`,
          },
        },
      )

      withdrawRes.data?.data?.map((history) => {
        if (history.status === 'Paid') {
          totalWithdraw += history.amount
        }
      })

      setTotalWithdraw(totalWithdraw)
    }

    getTotalAmount()
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        token,
        setToken,
        setUser,
        getUser,
        getDashboard,
        dash,
        setDash,
        widName,
        setWidName,
        getData,
        coins,
        loader,
        setLoader,
        getAllUser,
        allUsers,
        triggerFetchHistory,
        setTriggerFetchHistory,
        userData,
        totalDeposit,
        setTotalDeposit,
        totalWithdraw,
        setTotalWithdraw,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
