import { useEffect, useState } from 'react'
import '../Withdrawal/Withdrawal.css'
import './History.css'
import { TfiClose } from 'react-icons/tfi'
import client from '../../client'
import { useCookies } from 'react-cookie'

const HistoryUserDisplay = ({ onClose, email, history }) => {
  const [user, setUser] = useState()
  const [userDashboard, setUserDashboard] = useState()
  const [cookies] = useCookies(['user'])

  useEffect(() => {
    const fetchUser = async () => {
      const res = await client.post(
        '/pent/admin/getUser',
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.userToken}`,
          },
        },
      )
      setUser(res.data)
    }

    if (!user) {
      fetchUser()
    }
  }, [cookies.userToken, email, user])

  useEffect(() => {
    const fetchUser = async () => {
      const res = await client.get(
        `/pent/admin/getDashBoardData?email=${email}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.userToken}`,
          },
        },
      )
      setUserDashboard(res.data)
    }

    if (!userDashboard && history.type === 'Withdrawal') {
      fetchUser()
    }
  }, [cookies.userToken, email, history.type, user, userDashboard])

  return (
    <div className="withdrawal_con">
      <div className="withdraw_card">
        <div className="header">
          <div className="header_text">User Info</div>
          <TfiClose className="close_btn" onClick={onClose} />
        </div>

        {user && (
          <div className="history_user">
            <div className="history_user_subtitle">Main</div>
            <div>
              {Object.entries(user).map(([key, value]) =>
                ['password', '_id', '__v'].includes(key) ? null : (
                  <div key={key} className="history_user_entries">
                    <div> {key}:</div>{' '}
                    <div>
                      {(['createdAt', 'updatedAt'].includes(key)
                        ? new Date(value).toLocaleDateString('en-US', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                          })
                        : value) || '---'}
                    </div>
                  </div>
                ),
              )}
            </div>

            {history.type === 'Withdrawal' && userDashboard && (
              <>
                <div className="history_user_subtitle">Billing</div>
                {history.method.trim().toLowerCase() === 'bank transfer' && (
                  <div>
                    <div className="history_user_entries">
                      <div> Type:</div> <div>{history.type}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Method:</div> <div>{history.method}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Amount:</div> <div>${history.amount}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Bank:</div> <div>{history.bank || '---'}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Bank Address:</div>{' '}
                      <div>{history.bank_addr || '---'}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Bank City:</div>{' '}
                      <div>{history.bank_city || '---'}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Bank Country:</div>{' '}
                      <div>{history.bank_country || '---'}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Bank Account Number:</div>{' '}
                      <div>{history.bank_acctNum || '---'}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Bank Code:</div>{' '}
                      <div>{history.bank_code || '---'}</div>
                    </div>
                    <div className="history_user_entries">
                      <div> Bank Full Name:</div>{' '}
                      <div>{history.full_name || '---'}</div>
                    </div>
                  </div>
                )}

                {history.method.trim().toLowerCase() === 'crypto transfer' && (
                  <div>
                    <div className="history_user_entries">
                      <div> Coin:</div>{' '}
                      <div>{history.details.coin || '---'}</div>
                    </div>

                    <div className="history_user_entries">
                      <div> Wallet Address:</div>{' '}
                      <div>
                        {history.details.addr ||
                          userDashboard.walletAddress.find(
                            (walletAddress) =>
                              walletAddress.coin === history.details.coin,
                          )?.addr ||
                          '---'}
                      </div>
                    </div>

                    <div className="history_user_entries">
                      <div> Tag:</div>{' '}
                      <div>
                        {(history.details.addr
                          ? history.details.tag
                          : userDashboard.walletAddress.find(
                              (walletAddress) =>
                                walletAddress.coin === history.details.coin,
                            )?.tag) || '---'}
                      </div>
                    </div>

                    <div className="history_user_entries">
                      <div> Network:</div>{' '}
                      <div>
                        {(history.details.addr
                          ? history.details.network
                          : userDashboard.walletAddress.find(
                              (walletAddress) =>
                                walletAddress.coin === history.details.coin,
                            )?.network) || '---'}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default HistoryUserDisplay
