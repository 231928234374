import React, { useContext, useEffect, useState, useCallback } from 'react'
import client from '../../client'
import { useCookies } from 'react-cookie'
import { TfiClose } from 'react-icons/tfi'

import transactionSVG from '../../assets/undraw_credit_card_re_blml.svg'

import './History.css'
import { UserContext } from '../../context/UserContext'
import { nanoid } from 'nanoid'
import Paginator from './Paginator'
import HistoryTableRow from './HistoryTableRow'
import SuccessModal from '../modals/Success'
import ErrorModal from '../modals/Error'

const UserHistory = () => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })

  // hooks
  const { user } = useContext(UserContext)
  const [cookies] = useCookies(['user'])

  // general
  const [showSuccess, setShowSuccess] = useState({
    show: false,
    message: '',
    primaryBtnText: '',
  })
  const [showError, setShowError] = useState({
    show: false,
    message: '',
    primaryBtnText: '',
  })

  // update status
  const [approving, setApproving] = useState(false)
  const [declining, setDeclining] = useState(false)
  const [showMarkAsPaid, setShowMarkAsPaid] = useState(false)
  const [showDecline, setShowDecline] = useState(false)

  // main query
  const [history, setHistory] = useState([])
  const [type, setType] = useState()
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(false)
  const [transaction, setTransaction] = useState({
    _id: '',
    received: 0,
    note: '',
  })
  let totalAmountPaid = 0

  // methods
  const fetchHistory = useCallback(async () => {
    console.log('type', type)
    setFetching(true)
    const res = await client.get(
      `/pent/user/getHistory?limit=10&page=${page}` +
        (user.admin ? `&type=${type || 'Deposit'}` : ''),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.userToken}`,
        },
      },
    )
    console.log('data from the backend', res.data)
    setHistory(res.data)
    setFetching(false)
  }, [cookies.userToken, page, type, user])

  const handleMarkAsPaid = async (e) => {
    e.preventDefault()
    try {
      setApproving(true)
      await client.put('/pent/admin/approveWithdrawal', transaction, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.userToken}`,
        },
      })
      setShowMarkAsPaid(false)
      setShowSuccess({
        show: true,
        message: 'The withdrawal request has been successfully marked as paid.',
        primaryBtnText: 'View History',
      })
    } catch (err) {
      setShowError({
        show: true,
        message: 'Oops an error occurred when approving this request.',
      })
    } finally {
      setApproving(false)
    }
  }

  const handleDeclineWithdrawal = async (e) => {
    e.preventDefault()
    try {
      setDeclining(true)
      await client.put('/pent/admin/declineWithdrawal', transaction, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${cookies.userToken}`,
        },
      })
      setShowDecline(false)
      setShowSuccess({
        show: true,
        message:
          'The withdrawal request has been successfully declined and message sent to user.',
        primaryBtnText: 'View History',
      })
    } catch (err) {
      setShowError({
        show: true,
        message: 'Oops an error occurred when declining this request.',
      })
    } finally {
      setDeclining(false)
    }
  }

  const handleRetry = () => {
    setShowError((currShowError) => ({
      ...currShowError,
      message: '',
      show: false,
    }))
  }

  // side effects
  useEffect(() => {
    if (cookies) {
      fetchHistory()
    }
  }, [cookies, page, type, fetchHistory])

  history.data?.map((history) => {
    if (history.status === 'Paid') {
      totalAmountPaid += history.amount
    }
  })

  console.log("type ", type)
  return (
    <>
      <div className="history_table">
        {fetching && (
          <div className="loader__con">
            <span className="loader"></span>
          </div>
        )}
        {history.length === 0 ? (
          <div className="history_empty_wrapper">
            <div className="history_empty">
              <img src={transactionSVG} alt="Transaction Empty" />
              <div className="history_empty_text">
                No transactions recorded yet
              </div>
            </div>
          </div>
        ) : (
          <div className="dash__main">
            {user.admin && (
              <nav className="market-nav">
                <ul>
                  <li
                    style={{ color: '#576377', fontSize: '12px' }}
                    onClick={() => {
                      setType('Deposit')
                      setPage(1)
                    }}
                  >
                    Deposit
                  </li>
                  <li
                    style={{ color: '#576377', fontSize: '12px' }}
                    onClick={() => {
                      setType('Withdrawal')
                      setPage(1)
                    }}
                  >
                    Withdrawal
                  </li>
                </ul>

                <div>Total : {formatter.format(totalAmountPaid) ?? 0}</div>
              </nav>
            )}
            <table className="history_table">
              <tbody>
                <tr className="history_table_header">
                  {user.admin && <th>User</th>}
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>

                {history.data.map((history) => (
                  <HistoryTableRow
                    history={history}
                    user={user}
                    key={nanoid()}
                    onMarkHasPaid={(args) => {
                      setShowMarkAsPaid(true)
                      setTransaction(args)
                    }}
                    onDecline={(args) => {
                      setShowDecline(true)
                      setTransaction(args)
                    }}
                  />
                ))}
              </tbody>
            </table>

            <Paginator
              lastPage={history.pagination.totalPages}
              currPage={page}
              setPage={setPage}
            />
          </div>
        )}
      </div>

      {showMarkAsPaid && !showError.message.includes('approving') && (
        <div className="withdrawal_con">
          <div className="withdraw_card">
            <div className="header">
              <div className="header_text">Approve Withdrawal</div>
              <TfiClose
                className="close_btn"
                onClick={() => {
                  setShowMarkAsPaid(false)
                }}
              />
            </div>

            <form className="withdrawal_form" onSubmit={handleMarkAsPaid}>
              <div>
                <div>
                  <label>Amount Sent</label>
                </div>
                <input
                  type="number"
                  className="withdrawal_form_input"
                  placeholder="Specify amount sent"
                  value={
                    transaction.received === 0
                      ? ''
                      : transaction.received.toString()
                  }
                  onChange={(e) => {
                    setTransaction((currForm) => ({
                      ...currForm,
                      received: e.target.value,
                    }))
                  }}
                />
              </div>

              <div className="withdrawal_btn_wrapper">
                <button
                  className="withdrawal_button"
                  data-active={transaction.received && !approving}
                >
                  {approving ? 'Sending...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showDecline && !showError.message.includes('declining') && (
        <div className="withdrawal_con">
          <div className="withdraw_card">
            <div className="header">
              <div className="header_text">Decline Withdrawal</div>
              <TfiClose
                className="close_btn"
                onClick={() => {
                  setShowDecline(false)
                }}
              />
            </div>

            <form
              className="withdrawal_form"
              onSubmit={handleDeclineWithdrawal}
            >
              <div>
                <div>
                  <label>Note</label>
                </div>
                <input
                  type="text"
                  className="withdrawal_form_input"
                  placeholder="Specify reason for declining"
                  value={transaction.note}
                  onChange={(e) => {
                    setTransaction((currForm) => ({
                      ...currForm,
                      note: e.target.value,
                    }))
                  }}
                />
              </div>

              <div className="withdrawal_btn_wrapper">
                <button
                  className="withdrawal_button"
                  data-active={transaction.note && !declining}
                >
                  {declining ? 'Declining...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showSuccess.show && (
        <SuccessModal
          message={showSuccess.message}
          primaryBtnText={'View History'}
          onClickPrimaryBtn={() => {
            setPage(0)
            fetchHistory()
            setShowSuccess((currShowSuccess) => ({
              ...currShowSuccess,
              show: false,
            }))
          }}
        />
      )}

      {showError.show && (
        <ErrorModal
          message={showError.message}
          primaryBtnText="Retry"
          onClickPrimaryBtn={() => {
            handleRetry()
          }}
          onClose={() =>
            setShowError((currShowError) => ({ ...currShowError, show: false }))
          }
        />
      )}
    </>
  )
}

export default UserHistory
