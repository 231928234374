import client from '../client'

// Function to add copy trader profile
const addCopyTraderProfile = async (profileData) => {
  console.log({ profileData })
  try {
    const response = await client.post('/pent/admin/cpt/profile', profileData)
    // console.log({ response })
    return response.data
  } catch (error) {
    throw error
  }
}

// Function to update copy trader profile
const updateCopyTraderProfile = async (id, profileData) => {
  try {
    const response = await client.put(
      `/pent/admin/cpt/profile/${id}`,
      profileData,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

// Function to delete copy trader profile
const deleteCopyTraderProfile = async (id) => {
  try {
    const response = await client.delete(`/pent/admin/cpt/profile/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Function to get copy trader profile by ID
const getCopyTraderProfile = async (id) => {
  try {
    const response = await client.get(`/pent/admin/cpt/profile/${id}`)
    return response.data
  } catch (error) {
    throw error
  }
}

// Function to get all copy trader profiles
const getAllCopyTraderProfiles = async () => {
  try {
    const response = await client.get('/pent/admin/cpt/profiles')
    return response.data
  } catch (error) {
    throw error
  }
}

// COPY TRADING

// Function to follow a copy trader
const followCopyTrader = async (userId, copyTraderId) => {
  try {
    const response = await client.post(
      `/pent/cpt/${userId}/follow/${copyTraderId}`,
    )
    return response.data
  } catch (error) {
    throw new Error(
      error.response.data.message || 'Failed to follow copy trader',
    )
  }
}

// Function to unfollow a copy trader
const unfollowCopyTrader = async (userId, copyTraderId) => {
  try {
    const response = await client.delete(
      `/pent/cpt/${userId}/unfollow/${copyTraderId}`,
    )
    return response.data
  } catch (error) {
    throw new Error(
      error.response.data.message || 'Failed to unfollow copy trader',
    )
  }
}

// Function to get followed copy traders
const getFollowedCopyTraders = async (userId) => {
  try {
    const response = await client.get(`/pent/cpt/${userId}/followedCopyTraders`)
    return response.data
  } catch (error) {
    throw new Error(
      error.response.data.message || 'Failed to get followed copy traders',
    )
  }
}

export {
  addCopyTraderProfile,
  updateCopyTraderProfile,
  deleteCopyTraderProfile,
  getCopyTraderProfile,
  getAllCopyTraderProfiles,
  followCopyTrader,
  unfollowCopyTrader,
  getFollowedCopyTraders,
}
