import React, { createContext, useState } from 'react'

export const walletaddress = createContext({
  address: '',
  setAddress: () => {},
})
const WalletAddress = ({ children }) => {
  const [address, setAddress] = useState('')
  const [currentSymbol, setCurrentSymbol] = useState('')
  const [currentPayPrice, setCurrentPayPrice] = useState(0)

  return (
    <walletaddress.Provider
      value={{
        address,
        setAddress,
        currentSymbol,
        currentPayPrice,
        setCurrentPayPrice,
        setCurrentSymbol,
      }}
    >
      {children}
    </walletaddress.Provider>
  )
}

export default WalletAddress
