import { useContext } from 'react'
import './Overview.css'
import { UserContext } from '../../context/UserContext'

const OverviewDetails = ({ setO1, setTR, setUS }) => {
  const { user } = useContext(UserContext)

  return (
    <div className="details_con">
      <div className="details_section">
        <div className="details_left">
          <label>fullname:</label>
          <div>{user?.fullName}</div>

          <label>email:</label>
          <div>{user?.email}</div>
        </div>

        <div className="details_right">
          <label>Account creation date:</label>
          <div>{new Date(user?.createdAt).toLocaleDateString()}</div>
        </div>
      </div>

      <div
        className="balance_btn"
        onClick={() => {
          setO1(false)
          setUS(true)
          setTR(false)
        }}
      >
        <div className="mod_bal">modify balance</div>

        <div className="bal">${user?.amount}.00</div>

        <div className="avail_bal">available balance</div>
      </div>
    </div>
  )
}

export default OverviewDetails
