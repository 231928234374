import React, { useState } from 'react'
import { addCopyTraderProfile } from '../../../../utils/copytrader'
import toast from 'react-hot-toast'
import { BeatLoader } from 'react-spinners'

export default function AddCopyForm() {
  const [name, setName] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [email, setEmail] = useState('')
  const [winRate, setWinRate] = useState('')
  const [roi, setRoi] = useState('')
  const [profit, setProfit] = useState('')
  const [expertise, setExpertise] = useState('')
  const [copyLimit, setCopyLimit] = useState('')

  const [loader, setLoader] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoader(true)

    await addCopyTraderProfile({
      email,
      name,
      imgUrl,
      winRate,
      roi,
      profit,
      title: expertise,
      copyLimit,
    })
      .then((res) => {
        // console.log(res)
        toast.success('added successfully')
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message)
      })
      .finally(() => {
        setLoader(false)
        setEmail('')
        setName('')
        setImgUrl('')
        setWinRate('')
        setProfit('')
        setExpertise('')
      })
  }

  const handleCopyLimitChange = (e) => {
    const inputValue = e.target.value

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setCopyLimit(inputValue)
    }
  }

  return (
    <div>
      <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
        Add New Copy Trader
      </p>
      <br />

      <div className="cpt__divider"></div>
      <form onSubmit={handleSubmit} className="cpt__form">
        <input
          onChange={(e) => {
            setEmail(e.target.value)
          }}
          value={email}
          type="email"
          required
          placeholder="Copy Trader Email"
        />
        <input
          onChange={(e) => {
            setName(e.target.value)
          }}
          value={name}
          type="text"
          required
          placeholder="Copy Trader Name"
        />
        <input
          onChange={(e) => {
            setImgUrl(e.target.value)
          }}
          value={imgUrl}
          type="text"
          required
          placeholder="Copy Image URL"
        />
        <input
          onChange={(e) => {
            setWinRate(e.target.value)
          }}
          value={winRate}
          type="number"
          required
          placeholder="Win Rate"
        />
        <input
          onChange={(e) => {
            setRoi(e.target.value)
          }}
          value={roi}
          type="number"
          required
          placeholder="ROI"
        />
        <input
          onChange={(e) => {
            setProfit(e.target.value)
          }}
          value={profit}
          type="number"
          required
          placeholder="Total Profit"
        />
        <input
          onChange={(e) => {
            setExpertise(e.target.value)
          }}
          value={expertise}
          type="text"
          required
          placeholder="Trader Expertise"
        />

        <input
          onChange={handleCopyLimitChange}
          value={copyLimit}
          type="text"
          required
          placeholder="Trader copy limit in dollar"
        />
        <button type="submit" disabled={loader}>
          {loader ? <BeatLoader color="#fff " size={10} /> : 'Save'}
        </button>
      </form>
    </div>
  )
}
