import { useMemo, useState, useContext } from 'react'
import { UserContext } from '../../../context/UserContext'

import '../Withdrawal.css'

import { TfiClose } from 'react-icons/tfi'
import CoinList from '../../CoinList'
import client from '../../../client'
import { useCookies } from 'react-cookie'
import SuccessModal from '../../modals/Success'
import ErrorModal from '../../modals/Error'

const PAYOUT_METHODS = {
  BANK_TRANSFER: 'Bank Transfer',
  CRYPTO_WALLET: 'Crypto Transfer',
  CASH_APP: 'Cash App',
}

const RequestWithdrawal = ({ onClose }) => {
  const { user, getUser } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [cookies] = useCookies(['user'])
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const [form, setForm] = useState({
    amount: 0,
    method: PAYOUT_METHODS.BANK_TRANSFER,
    pin: '',
    details: {
      coin: '',
      addr: '',
      network: '',
      tag: '',
    },
  })

  const balance = useMemo(() => {
    if (user) {
      return user.amount
    }
    return 0
  }, [user])

  const formCompleted = useMemo(() => {
    let valid =
      form.amount &&
      Number(form.amount) > 0 &&
      balance !== 0 &&
      balance >= form.amount
        ? true
        : false

    if (form.method === PAYOUT_METHODS.CRYPTO_WALLET) {
    }
    return valid
  }, [form, balance])

  const handleWithdraw = async (e) => {
    e.preventDefault()
    if (formCompleted && cookies) {
      try {
        setLoading(true)
        await client.put('/pent/user/withdraw', form, {
          headers: {
            Authorization: `Bearer ${cookies.userToken}`,
          },
        })
        setShowSuccess(true)
        await getUser()
      } catch (err) {
        setShowError(true)
      } finally {
        setLoading(false)
      }
    }
  }

  if (showSuccess) {
    return (
      <SuccessModal
        message={
          'Withdrawal request successfully submitted. An administrator will now review and process the request'
        }
        primaryBtnText={'Complete'}
        onClickPrimaryBtn={() => {
          setShowSuccess(false)
          onClose()
        }}
      />
    )
  }

  if (showError) {
    return (
      <ErrorModal
        message={'Oops an error occurred when submitting request'}
        primaryBtnText={'Retry'}
        onClickPrimaryBtn={() => {
          setShowError(false)
        }}
        onClose={() => {
          setShowError(false)
          onClose()
        }}
      />
    )
  }
  return (
    <div className="withdrawal_con">
      <div className="withdraw_card">
        <div className="header">
          <div className="header_text">Request Withdrawal</div>
          <TfiClose className="close_btn" onClick={onClose} />
        </div>

        <form className="withdrawal_form" onSubmit={handleWithdraw}>
          <div>
            <div>
              <label>Amount</label>
            </div>
            <div className="withdraw_form_input_wrapper">
              <input
                type="number"
                className="withdrawal_form_input"
                name="amount"
                onChange={(e) => {
                  setForm((currForm) => ({
                    ...currForm,
                    amount: e.target.value,
                  }))
                }}
                value={form.amount === 0 ? '' : form.amount.toString()}
                placeholder="Amount to withdraw"
              />
              <div className="withdraw_form_input_balance">
                Balance: ${user ? user.amount.toFixed(2) : '0.00'}
              </div>
            </div>
          </div>

          <div>
            <div>
              <label>Referral Pin (Optional)</label>
            </div>
            <input
              className="withdrawal_form_input"
              name="pin"
              type="number"
              onChange={(e) => {
                setForm((currForm) => ({
                  ...currForm,
                  pin: e.target.value,
                }))
              }}
              value={form.pin}
              maxLength={4}
              minLength={4}
              placeholder="Enter Pin"
            />
          </div>

          <div className="payment_method_con">
            <div>
              <label>Payout Method</label>
            </div>

            <div className="withdrawal_form_options">
              <div
                onClick={() =>
                  setForm((currForm) => ({
                    ...currForm,
                    method: PAYOUT_METHODS.BANK_TRANSFER,
                  }))
                }
                data-active={form.method === PAYOUT_METHODS.BANK_TRANSFER}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M11.5 1L2 6v2h19V6m-5 4v7h3v-7M2 22h19v-3H2m8-9v7h3v-7m-9 0v7h3v-7z"
                  ></path>
                </svg>
                <div>Bank Transfer</div>
              </div>

              <div
                onClick={() =>
                  setForm((currForm) => ({
                    ...currForm,
                    method: PAYOUT_METHODS.CRYPTO_WALLET,
                  }))
                }
                data-active={form.method === PAYOUT_METHODS.CRYPTO_WALLET}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="256"
                  height="256"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M168 152a24 24 0 01-24 24h-40v-48h40a24 24 0 0124 24m64-24A104 104 0 11128 24a104.11 104.11 0 01104 104m-48 24a40 40 0 00-17.63-33.15A32 32 0 00152 65v-9a8 8 0 00-16 0v8h-16v-8a8 8 0 00-16 0v8H88a8 8 0 000 16v96a8 8 0 000 16h16v8a8 8 0 0016 0v-8h16v8a8 8 0 0016 0v-8.81A40.05 40.05 0 00184 152m-24-56a16 16 0 00-16-16h-40v32h40a16 16 0 0016-16"
                  ></path>
                </svg>
                <div>Crypto Address</div>
              </div>

              <div
                onClick={() =>
                  setForm((currForm) => ({
                    ...currForm,
                    method: PAYOUT_METHODS.CASH_APP,
                  }))
                }
                data-active={form.method === PAYOUT_METHODS.CASH_APP}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="256"
                  height="256"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="M128,24C68.48,24,24,68.48,24,128s44.48,104,104,104,104-44.48,104-104S187.52,24,128,24Zm8,159.92c-4.16,1.6-8.64,2.08-13,1.52-11.76-1.52-19.28-7.76-19.28-16.4a8,8,0,0,1,16,0c0,2.16,3.6,4.4,9.28,5.12,6.88.88,14.72-.64,18.32-6.24,4-6.08,3.12-11.76-2.88-16.24-2.88-2.16-6.24-3.6-9.84-4.8-1.44-.48-3.2-1.12-4.64-1.68-4.56-1.68-8.72-3.6-12.48-5.68-10.24-5.36-15.36-14.56-12.96-24.48,3.12-12.56,16.72-20.4,30.96-17.52,7.52,1.52,13.6,5.28,17.28,10.56,2.4,3.36,3.84,7.2,4.24,11.36a8,8,0,0,1-16,1.36c-.16-1.52-1.28-3.76-2.72-5.68-2.64-3.6-6.96-6-11.6-6.88-6.8-1.36-12.32.96-13.92,6.08-1.84,5.76,2.72,9.12,6.88,11.36.96.48,2.16,1.04,3.52,1.52l1.92.64c4.8,1.6,10.16,3.36,14.4,6.08,8.88,5.76,12.32,14.32,11.44,22.56C145.68,149.12,138.4,162.16,136,163.92ZM128,32c53,0,96,43,96,96s-43,96-96,96-96-43-96-96S75,32,128,32Z"
                  ></path>
                </svg>
                <div>Cash App</div>
              </div>
            </div>
          </div>

          {form.method === PAYOUT_METHODS.CRYPTO_WALLET && (
            <>
              <CoinList
                label="Crypto Currency"
                setCoin={(newCoin) =>
                  setForm((currForm) => ({
                    ...currForm,
                    details: { ...currForm.details, coin: newCoin },
                  }))
                }
                currCoin={form.details.coin}
              />

              <div>
                <div>
                  <label>Wallet Address</label>
                </div>
                <input
                  type="text"
                  className="withdrawal_form_input"
                  placeholder="Specify wallet address"
                  value={form.details.addr}
                  onChange={(e) => {
                    setForm((currForm) => ({
                      ...currForm,
                      details: { ...currForm.details, addr: e.target.value },
                    }))
                  }}
                />
              </div>

              <div>
                <div>
                  <label>Network (Optional)</label>
                </div>
                <input
                  type="text"
                  className="withdrawal_form_input"
                  placeholder="Select Network"
                  value={form.details.network}
                  onChange={(e) => {
                    setForm((currForm) => ({
                      ...currForm,
                      details: { ...currForm.details, network: e.target.value },
                    }))
                  }}
                />
              </div>
            </>
          )}

          {form.method === PAYOUT_METHODS.CASH_APP && (
            <>
              <div>
                <div>
                  <label>CASH TAG</label>
                </div>
                <input
                  type="text"
                  className="withdrawal_form_input"
                  placeholder="cash tag"
                  value={form.details.tag}
                  onChange={(e) => {
                    setForm((currForm) => ({
                      ...currForm,
                      details: {
                        ...currForm.details,
                        tag: e.target.value,
                      },
                    }))
                  }}
                />
              </div>
            </>
          )}

          {form.method === PAYOUT_METHODS.BANK_TRANSFER && (
            <div className="withdrawal_bank_info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#FFB636"
                  d="M12.51 470.379L234.371 16.008c6.439-13.187 25.17-13.363 31.855-.299l232.51 454.371c6.064 11.849-2.542 25.92-15.853 25.92H28.512c-13.164 0-21.778-13.791-16.002-25.621"
                ></path>
                <path
                  fill="#2B3B47"
                  d="M284.332 173L272.15 336.498c-.911 12.233-11.567 21.411-23.8 20.499-11.116-.828-19.706-9.707-20.499-20.499L215.668 173c-1.413-18.961 12.813-35.478 31.774-36.89s35.478 12.813 36.89 31.774c.124 1.662.109 3.5 0 5.116M250 391.873c-17.432 0-31.564 14.131-31.564 31.564C218.436 440.869 232.568 455 250 455s31.564-14.131 31.564-31.564c0-17.432-14.132-31.563-31.564-31.563"
                ></path>
              </svg>
              <div>
                Ensure you update your bank information before requesting a
                withdrawal for faster processing. Thank you!
              </div>
            </div>
          )}

          {form.method === PAYOUT_METHODS.CRYPTO_WALLET && (
            <div className="withdrawal_bank_info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#FFB636"
                  d="M12.51 470.379L234.371 16.008c6.439-13.187 25.17-13.363 31.855-.299l232.51 454.371c6.064 11.849-2.542 25.92-15.853 25.92H28.512c-13.164 0-21.778-13.791-16.002-25.621"
                ></path>
                <path
                  fill="#2B3B47"
                  d="M284.332 173L272.15 336.498c-.911 12.233-11.567 21.411-23.8 20.499-11.116-.828-19.706-9.707-20.499-20.499L215.668 173c-1.413-18.961 12.813-35.478 31.774-36.89s35.478 12.813 36.89 31.774c.124 1.662.109 3.5 0 5.116M250 391.873c-17.432 0-31.564 14.131-31.564 31.564C218.436 440.869 232.568 455 250 455s31.564-14.131 31.564-31.564c0-17.432-14.132-31.563-31.564-31.563"
                ></path>
              </svg>
              <div>
                Optional Fields: Your withdrawal, by default, will be directed
                to the wallet specified in your profile. Kindly review and
                update your details accordingly.
              </div>
            </div>
          )}

          <div className="withdrawal_btn_wrapper">
            <button
              className="withdrawal_button"
              data-active={formCompleted && !loading}
            >
              {loading ? 'Sending...' : 'Send Request'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RequestWithdrawal
