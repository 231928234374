import React, { useState } from 'react'
import HistoryUserDisplay from './HistoryUserDisplay'
import HistoryUserTransactionInfo from './HistoryUserTransactionInfo'

const HistoryTableRow = ({ history, user, onMarkHasPaid, onDecline }) => {
  const [appUserEmail, setAppUserEmail] = useState()
  const [showTransactionInfo, setShowTransactionInfo] = useState(false)
  return (
    <>
      <tr className="history_table_data">
        {user.admin && <td>{history.email}</td>}
        <td>{history.type}</td>
        <td>${history.amount.toFixed(3)}</td>
        <td>{history.method}</td>
        <td className="history_table_status" data-status={history.status}>
          {history.status}
        </td>
        <td>
          {new Date(history.createdAt).toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </td>
        <td className="history_table_action" tabIndex={0}>
          <div>Expand </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>

          <div className="history_table_action_options" tabIndex={0}>
            {history.type.toLowerCase() === 'withdrawal' &&
              history.status.toLowerCase() === 'pending' &&
              user.admin && (
                <div
                  className="history_table_action_option"
                  onClick={() =>
                    onMarkHasPaid({
                      _id: history._id,
                      received: history.amount,
                    })
                  }
                >
                  Mark As Paid
                </div>
              )}
            {history.type.toLowerCase() === 'withdrawal' &&
              history.status.toLowerCase() === 'pending' &&
              user.admin && (
                <div
                  className="history_table_action_option"
                  onClick={() =>
                    onDecline({
                      _id: history._id,
                    })
                  }
                >
                  Decline
                </div>
              )}

            {user.admin && (
              <div
                className="history_table_action_option"
                onClick={() => setAppUserEmail(history.email)}
              >
                Display User Info
              </div>
            )}

            {!user.admin && (
              <div
                className="history_table_action_option"
                onClick={() => setShowTransactionInfo(true)}
              >
                Display Info
              </div>
            )}
          </div>
        </td>
      </tr>

      {appUserEmail && (
        <HistoryUserDisplay
          email={appUserEmail}
          onClose={() => setAppUserEmail()}
          history={history}
        />
      )}

      {showTransactionInfo && (
        <HistoryUserTransactionInfo
          history={history}
          onClose={() => setShowTransactionInfo(false)}
        />
      )}
    </>
  )
}

export default HistoryTableRow
