import './Sidebar.css'
import { nanoid } from 'nanoid'

import { BsClipboard2Data } from 'react-icons/bs'
import { BsHeartPulse } from 'react-icons/bs'
import { ImStack } from 'react-icons/im'
import { RiAdminFill } from 'react-icons/ri'
import { CgCalculator } from 'react-icons/cg'
import { BiCopy } from 'react-icons/bi'
import { HiOutlineGlobeEuropeAfrica } from 'react-icons/hi2'
import { Link, useLocation } from 'react-router-dom'
import orderIcon from '../../../assets/dashboard/orderbook.svg'
import { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import { FaCopyright } from 'react-icons/fa'
import { IoMdPersonAdd } from 'react-icons/io'

const Sidebar = ({ handleWidget }) => {
  const location = useLocation()
  const { user } = useContext(UserContext)

  const links = [
    {
      title: 'Board',
      url: '/dashboard',
      icon: (
        <BsClipboard2Data color="black" className="sidebar_icon" size={20} />
      ),
    },
    {
      title: 'Order book',
      url: '/orderbook',
      icon: <img src={orderIcon} style={{ width: 30 }} alt="orderbook" />,
    },
    {
      title: 'History',
      url: '/history',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            d="M2 7h18m-4-5l5 5-5 5m6 5H4m4-5l-5 5 5 5"
          ></path>
        </svg>
      ),
    },
    {
      title: 'Market',
      url: '/market',
      icon: <BsHeartPulse color="black" className="sidebar_icon" size={20} />,
    },
    {
      title: 'Copy Trading',
      url: '/copytrading',
      icon: <FaCopyright className="sidebar_icon" color="black" size={20} />,
    },

    {
      title: 'News',
      url: '/news',
      icon: (
        <HiOutlineGlobeEuropeAfrica
          color="black"
          className="sidebar_icon"
          size={20}
        />
      ),
    },
  ]

  const adminLinks = [
    {
      title: 'Board',
      url: '/dashboard',
      icon: (
        <BsClipboard2Data color="black" className="sidebar_icon" size={20} />
      ),
    },
    {
      title: 'Order book',
      url: '/orderbook',
      icon: <img src={orderIcon} style={{ width: 30 }} alt="orderbook" />,
    },
    {
      title: 'History',
      url: '/history',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            d="M2 7h18m-4-5l5 5-5 5m6 5H4m4-5l-5 5 5 5"
          ></path>
        </svg>
      ),
    },
    {
      title: 'Market',
      url: '/market',
      icon: <BsHeartPulse color="black" className="sidebar_icon" size={20} />,
    },
    {
      title: 'Copy Trading',
      url: '/copytrading',
      icon: <FaCopyright className="sidebar_icon" color="black" size={20} />,
    },

    {
      title: 'Add Traders',
      url: '/admin/copytrading',
      icon: <IoMdPersonAdd className="sidebar_icon" color="black" size={20} />,
    },

    {
      title: 'News',
      url: '/news',
      icon: (
        <HiOutlineGlobeEuropeAfrica
          color="black"
          className="sidebar_icon"
          size={20}
        />
      ),
    },
    {
      title: 'Admin',
      url: '/admin',
      icon: <RiAdminFill className="sidebar_icon" color="black" size={20} />,
    },
  ]

  return (
    <nav className="sidebar__con">
      {user?.admin ? (
        <>
          {adminLinks.map((link, index) => (
            <Link
              key={nanoid()}
              to={link.url}
              onClick={handleWidget}
              className={`sidebar__link ${
                location.pathname === link.url
                  ? 'side__icon__selected side__icon__con'
                  : 'side__icon__con'
              }`}
            >
              {link.icon}
              <div className="side_text desk">{link.title}</div>
            </Link>
          ))}
        </>
      ) : (
        <>
          {links.map((link, index) => (
            <Link
              key={nanoid()}
              to={link.url}
              onClick={handleWidget}
              className={`sidebar__link ${
                location.pathname === link.url
                  ? 'side__icon__selected side__icon__con'
                  : 'side__icon__con'
              }`}
            >
              {link.icon}
              <div className="side_text desk">{link.title}</div>
            </Link>
          ))}
        </>
      )}
    </nav>
  )
}

export default Sidebar
