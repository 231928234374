import './Deposit.css'
import coinbase from '../../../assets/coinbasecommerce.svg'
import { useContext, useState, useEffect } from 'react'
import axios from 'axios'
import client from '../../../client'
import { UserContext } from '../../../context/UserContext'
import { useCookies } from 'react-cookie'
import { walletaddress } from '../../../context/WalletAddress'

const Deposit = ({ setD1, setShowTransferModal, setShowBalance }) => {
  const [amount, setAmount] = useState(0)
  const { user } = useContext(UserContext)
  const [cookies] = useCookies(['user'])
  const [isLoading, setIsLoading] = useState(false)
  const [btcPrice, setBtcPrice] = useState(0)
  const [ethPrice, setEthPrice] = useState(0)
  const [dogePrice, setDogePrice] = useState(0)
  const [usdtPrice, setUsdtPrice] = useState(0)
  const [assetsToken, setAssetToken] = useState([])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')

  const handleSelectChange = (event) => {
    setSelectedPaymentMethod(event.target.value)
    setCurrentSymbol(event.target.value)
  }

  const {
    setAddress,
    currentPayPrice,
    setCurrentPayPrice,
    setCurrentSymbol,
    currentSymbol,
  } = useContext(walletaddress)

  const addFunds = async (e) => {
    e.preventDefault()
    await client
      .post(
        '/pent/user/coinbase/',
        {
          email: user?.email,
          product: {
            name: user?.fullName,
            id: user?._id,
            description: 'Deposit into Duplitrades',
            price: amount,
            currency: 'USD',
            crypto: 'USD',
            method: 'coinbase ecommerce',
            status: 'Not Paid',
            type: 'Deposit',
            redirect_url:
              'https://duplitrades-server.onrender.compent/user/fundAccount',
            cancel_url: 'https://duplitrades.com/',
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.userToken}`,
          },
        },
      )
      .then((res) => {
        console.log(res.data.hosted_url)
        console.log(user?._id)
        window.location.href = `${res.data.hosted_url}`
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChange = (e) => {
    const inputValue = e.target.value

    console.log('input value', inputValue)
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setAmount(inputValue)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setIsLoading(true)

    switch (selectedPaymentMethod) {
      case 'BTC':
        setAddress('1AHFYemfdxyoLSqQJKBkmt8wHRWfuXh5Kp')
        break

      case 'ETH':
        setAddress('0xddfc45f6b6f54e764d7825ea8c612c32cf98d5c7')
        break

      case 'DOGE':
        setAddress('DMFetg1ae35z8MUvZ8zwcaKhdR9Pe9ThnV')
        break

      case 'USDT':
        setAddress('TQV8U3rr3G7jQAt1WV9YfJS95YUobJ1Ljm')
        break

      default:
        break
    }
    // setTransfer(true
    // Handle form submission logic here
  }

  useEffect(() => {
    if (currentSymbol === 'BTC') {
      console.log('price is btc', btcPrice)
      setCurrentPayPrice(amount / btcPrice)
    } else if (currentSymbol === 'ETH') {
      setCurrentPayPrice(amount / ethPrice)
    } else if (currentSymbol === 'DOGE') {
      setCurrentPayPrice(amount / dogePrice)
    } else if (currentSymbol === 'USDT') {
      setCurrentPayPrice(amount / usdtPrice)
    }
  }, [currentSymbol, amount])

  useEffect(() => {
    let timeOutId

    if (isLoading) {
      timeOutId = setTimeout(() => {
        setD1(false)
        setShowBalance(false)
        setShowTransferModal(true)
      }, 3000)
    }

    return () => clearTimeout(timeOutId)
  }, [isLoading, setD1, setShowTransferModal])

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get('https://api.coincap.io/v2/assets')

      setAssetToken(response.data.data)
    }

    fetch()
  }, [])

  useEffect(() => {
    for (let i = 0; i < assetsToken.length; i++) {
      if (assetsToken[i].symbol === 'BTC') {
        setBtcPrice(Number(assetsToken[i].priceUsd))
      } else if (assetsToken[i].symbol === 'ETH') {
        setEthPrice(Number(assetsToken[i].priceUsd))
      } else if (assetsToken[i].symbol === 'DOGE') {
        setDogePrice(Number(assetsToken[i].priceUsd))
      } else if (assetsToken[i].symbol === 'USDT') {
        setUsdtPrice(Number(assetsToken[i].priceUsd))
      }
    }
  }, [assetsToken])
  return (
    <div className="deposit_section">
      <div className="deposit_left">
        <div className="currency_block">usd</div>
      </div>
      <div className="deposit_right">
        <div className="dep_right_text">deposit amount</div>

        {currentSymbol && (
          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {Number(currentPayPrice).toPrecision(3) || 0} {currentSymbol}
          </div>
        )}

        <form onSubmit={handleSubmit} className="deposit_form">
          <div>
            <label>Amount</label>
            <div className="deposit_amount">
              <span>USD</span>
              <input
                onChange={handleChange}
                value={amount}
                className="amount_input "
                type="text"
              />
            </div>
          </div>

          <div>
            <label>Select Payment Method</label>
            <select value={selectedPaymentMethod} onChange={handleSelectChange}>
              <option value="">Select a payment method</option>
              <option value="BTC">Bitcoin (BTC)</option>
              <option value="ETH">Ethereum (ETH)</option>
              <option value="DOGE">Doge (DOGECOIN)</option>
              <option value="USDT">USDT (TRC20)</option>
            </select>
          </div>

          {!isLoading ? (
            <button
              disabled={!amount || !selectedPaymentMethod}
              className={`proceed_btn ${
                (!amount || !selectedPaymentMethod) && 'disable_btn'
              }`}
            >
              Proceed
            </button>
          ) : (
            <div className="deposit_loading"></div>
          )}
        </form>
      </div>
    </div>
  )
}

export default Deposit
