import { useEffect, useState } from 'react'
import './UserFooter.css'

const UserFooter = ({
  closeProfile,
  setShowAccountList,
  setShowNotifications,
  setShowBalance,
}) => {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const formattedTime = currentTime.toLocaleString('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
  return (
    <div
      className="user__footer"
      onClick={() => {
        closeProfile()
        setShowAccountList(false)
        setShowNotifications(false)
        setShowBalance(false)
      }}
    >
      <div className="user__footer__theme"></div>
      <div className="user__footer__time">
        <div className="user__footer__text">Contact us</div>
        <div className="user__footer__text">{formattedTime}</div>
      </div>
    </div>
  )
}

export default UserFooter
