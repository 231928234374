import './Balance.css'
import { TfiClose } from 'react-icons/tfi'
import Deposit from '../deposit/Deposit'
import Credit from '../credit/Credit'
import { useState } from 'react'

const Balance = ({ setShowBalance, setShowTransferModal }) => {
  const [cr, setCR] = useState(true)
  const [d1, setD1] = useState(false)

  return (
    <div className="addfunds_con">
      <div className="addfunds_card">
        <div className="header">
          <div className="header_text">Make a deposit</div>
          <TfiClose
            className="close_btn"
            onClick={() => setShowBalance(false)}
          />
        </div>

        <main>
          {cr && <Credit setCR={setCR} setD1={setD1} />}
          {d1 && (
            <Deposit
              setD1={setD1}
              setShowBalance={setShowBalance}
              setShowTransferModal={setShowTransferModal}
            />
          )}
        </main>
      </div>
    </div>
  )
}

export default Balance
