import React from 'react'
import './CopyLimitModal.css'

const CopyLimitModal = ({ isOpen, setIsOpen, copyLimit }) => {
  if (!isOpen) return null

  console.log('opening ')
  return (
    <div class="copy_modal">
      <div class="copy_modal_content">
        <p>
          You need at least ${copyLimit} in your account to copy this Trader,
          this amount will not be deducted from your account
        </p>

        <button onClick={() => setIsOpen(false)} className="copy_btn">
          Ok
        </button>
      </div>
    </div>
  )
}

export default CopyLimitModal
