/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import './CopyTrading.css'
import { MdDelete, MdKeyboardArrowRight } from 'react-icons/md'
import { IoMdClose } from 'react-icons/io'
import { AiFillPlusCircle } from 'react-icons/ai'
import {
  followCopyTrader,
  getAllCopyTraderProfiles,
  getFollowedCopyTraders,
  unfollowCopyTrader,
} from '../../utils/copytrader'
import { UserContext } from '../../context/UserContext'
import toast from 'react-hot-toast'
import NoDataImg from '../../assets/copyTrading/nodata.jpg'
import CopyLimitModal from '../../components/modals/CopyLimitModal'

export default function CopyTrading() {
  const { user } = useContext(UserContext)

  const [traderData, setTraderData] = useState([])
  const [followData, setFollowData] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [currentTrader, setCurrentTrader] = useState()

  const getTraders = async () => {
    const res = await getAllCopyTraderProfiles()

    setTraderData(res)

    // console.log(res)
  }

  const getFollowingTraders = async () => {
    const res = await getFollowedCopyTraders(user?._id)

    setFollowData(res)

    // console.log({ res })
  }

  useEffect(() => {
    getTraders()
    getFollowingTraders()
  }, [followData, user])

  const handleFollow = async (item) => {
    setCurrentTrader(item)
    console.log(user?._id, item?._id)

    const balance = user?.amount

    const copyLimit = item?.copyLimit || 0

    if (balance >= copyLimit) {
      await followCopyTrader(user?._id, item?._id)
        .then((res) => {
          console.log(res)
          toast.success(`copying ${item?.name}`)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message)
        })
    } else {
      console.log('else running')
      setOpenModal(true)
    }
  }

  const handleUnFollow = async (item) => {
    console.log(user?._id, item?._id, 'from delete')
    await unfollowCopyTrader(user?._id, item?._id)
      .then((res) => {
        console.log(res)
        toast.success(`copy cancelled for ${item?.name}`)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message)
      })
  }

  return (
    <>
      <div className="cpt__con">
        <div className="cpt__header">
          <p style={{ color: '#29c368' }}>Dashboard</p>
          <MdKeyboardArrowRight size={22} />
          <p>Traders</p>
        </div>

        {/* main */}

        <div className="cpt__main">
          {/* left side */}
          <div className="cpt__trader__con">
            <p>My Traders</p>
            <div className="cpt__divider"></div>

            {/* trader profile card */}

            {followData?.map((item, index) => (
              <div key={index} className="cpt__trader__profile">
                <div className="cpt__trader__content">
                  <div
                    style={{
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: '0.5rem',
                    }}
                  >
                    {' '}
                    <div className="cpt__trader__box">
                      <img src={item?.imgUrl} alt="" />
                    </div>{' '}
                    <div>
                      <p>{String(item?.name).toUpperCase()}</p>
                      <p style={{ fontWeight: 'normal' }}>
                        ID: {String(item?._id).slice(0, 8)}
                      </p>
                    </div>
                  </div>
                  <br />
                  <p>
                    Profit:{' '}
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      {item?.profit.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </span>
                  </p>
                </div>

                <div className="cpt__trader__chart">
                  <p style={{ color: 'lightgreen', fontWeight: 'bold' }}>
                    <span style={{ color: 'black' }}>ROI:</span> {item?.roi}%
                  </p>

                  {/* <LineChart
                  data={[{ roi: 0 }, { roi: 50 }, { roi: item?.roi }]}
                /> */}

                  <div style={{ flex: 1 }}></div>

                  <MdDelete
                    className="trader__delete"
                    onClick={() => handleUnFollow(item)}
                    style={{ cursor: 'pointer', color: 'red' }}
                    size={25}
                  />
                </div>
              </div>
            ))}

            {followData?.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {' '}
                <img src={NoDataImg} alt="" />
                <br />
                <p>You are not currently following a trader</p>{' '}
              </div>
            )}
          </div>

          {/* right side */}

          <div className="cpt__copytrader__con">
            {traderData?.map((item, index) => (
              <div key={index} className="cpt__copytrader__card">
                <img src={item?.imgUrl} alt="" />

                <div className="cpt__copytrader__text">
                  <h3>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      {item?.name}{' '}
                      <svg
                        className="verify_svg"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <circle cx="12" cy="12" r="12" fill="#1DA1F2" />
                        <path
                          fill="#ffffff"
                          d="M10.29 16.29l-3.59-3.59 1.42-1.42 2.17 2.17 5.59-5.59 1.42 1.42-7 7z"
                        />
                      </svg>
                    </span>
                    <span style={{ color: 'green', fontSize: 12 }}>
                      {followData?.some(
                        (followedTrader) => followedTrader?._id === item?._id,
                      ) && 'You are following this trader'}
                    </span>
                  </h3>
                  <p>Win Rate: {item?.winRate}%</p>
                  <p>ROI: {item?.roi}%</p>

                  <p>
                    Profit:{' '}
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      {item?.profit.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </span>
                  </p>

                  <p>{item?.title}</p>
                </div>

                {followData?.some(
                  (followedTrader) => followedTrader?._id === item?._id,
                ) ? (
                  <IoMdClose
                    onClick={() => handleUnFollow(item)}
                    style={{ cursor: 'pointer', color: '#36a6da' }}
                    size={25}
                  />
                ) : (
                  <AiFillPlusCircle
                    onClick={() => handleFollow(item)}
                    style={{ cursor: 'pointer', color: '#36a6da' }}
                    size={25}
                  />
                )}
              </div>
            ))}

            <br />
            <br />
            <br />
            <br />
            <br />

            {/* {traderData?.length === 0 && <img src={NoDataImg} alt="" />} */}
          </div>
        </div>
      </div>

      <CopyLimitModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        copyLimit={currentTrader?.copyLimit || 0}
      />
    </>
  )
}
