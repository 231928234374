import React, { useEffect, useRef } from 'react'

let tvScriptLoadingPromise

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef()

  useEffect(() => {
    onLoadScriptRef.current = createWidget

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script')
        script.id = 'tradingview-widget-loading-script'
        script.src = 'https://s3.tradingview.com/tv.js'
        script.type = 'text/javascript'
        script.onload = resolve

        document.head.appendChild(script)
      })
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current(),
    )

    return () => (onLoadScriptRef.current = null)

    function createWidget() {
      if (
        document.getElementById('tradingview_444e9') &&
        'TradingView' in window
      ) {
        new window.TradingView.widget({
          width: '100%',
          height: 800,
          symbol: 'NASDAQ:AAPL',
          interval: 'D',
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          hide_top_toolbar: true,
          hide_side_toolbar: false,
          allow_symbol_change: true,
          container_id: 'tradingview_444e9',
        })
      }
    }
  }, [])

  return (
    <div className="tra_widget tr-ma">
      <div className="tradingview-widget-container">
        <div id="tradingview_444e9" />
      </div>
    </div>
  )
}
