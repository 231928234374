import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../../context/UserContext'

const NewsPage = () => {
  const { setWidName } = useContext(UserContext)

  useEffect(() => {
    setWidName('news')
  })

  return (
    <>
      <div className="news__main">{/* <Calc /> */}</div>
    </>
  )
}

export default NewsPage
