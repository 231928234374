import React, { useContext } from 'react'

import { UserContext } from '../../context/UserContext'
import UserHistory from '../../components/History/UserHistory'

const History = () => {
  const { user } = useContext(UserContext)
  if (user) {
    return <UserHistory />
  }
}

export default History
