import React from 'react'
import './Paginator.css'

const Paginator = ({ lastPage, currPage, setPage }) => {
  const renderPageNumbers = () => {
    const pageNumbers = []
    const maxPagesToShow = 7

    if (lastPage <= maxPagesToShow) {
      // If there are fewer pages than the maxPagesToShow, display all pages
      for (let i = 1; i <= lastPage; i++) {
        pageNumbers.push(
          <div key={i} onClick={() => setPage(i)} data-active={currPage === i}>
            {i}
          </div>,
        )
      }
    } else {
      // Display the first page
      pageNumbers.push(
        <div key={1} onClick={() => setPage(1)} data-active={currPage === 1}>
          {1}
        </div>,
      )

      // Display ellipsis if there are more pages before the middle pages
      if (currPage > Math.ceil(maxPagesToShow / 2) + 1) {
        pageNumbers.push(
          <div key={'ellipsis1'} className="ellipsis">
            ....
          </div>,
        )
      }

      // Calculate the start and end page numbers to display
      let startPage = Math.max(2, currPage - Math.floor(maxPagesToShow / 2))
      let endPage = Math.min(lastPage - 1, startPage + maxPagesToShow - 3)

      // Display page numbers within the calculated range
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <div key={i} onClick={() => setPage(i)} data-active={currPage === i}>
            {i}
          </div>,
        )
      }

      // Display ellipsis if there are more pages after the middle pages
      if (endPage < lastPage - 1) {
        pageNumbers.push(
          <div key={'ellipsis2'} className="ellipsis">
            ....
          </div>,
        )
      }

      // Display the last page
      pageNumbers.push(
        <div
          key={lastPage}
          onClick={() => setPage(lastPage)}
          data-active={currPage === lastPage}
        >
          {lastPage}
        </div>,
      )
    }

    return pageNumbers
  }

  return <div className="paginator">{renderPageNumbers()}</div>
}

export default Paginator
