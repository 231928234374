import React from 'react'
import TradingViewWidget from '../TradingViewChart/TradingViewWidget'

const Board = () => {
  return (
    <>
      <div className="dash__main">
        <TradingViewWidget />
      </div>
    </>
  )
}

export default Board
