import React, { useState } from 'react'
import { updateCopyTraderProfile } from '../../../../utils/copytrader'
import toast from 'react-hot-toast'
import { BeatLoader } from 'react-spinners'
import { IoMdArrowRoundBack } from 'react-icons/io'

export default function UpdateCopyForm({ data, setUpdateToggle }) {
  const [name, setName] = useState(data?.name)
  const [imgUrl, setImgUrl] = useState(data?.imgUrl)
  const [email, setEmail] = useState(data?.email)
  const [winRate, setWinRate] = useState(data?.winRate)
  const [roi, setRoi] = useState(data?.roi)
  const [profit, setProfit] = useState(data?.profit)
  const [expertise, setExpertise] = useState(data?.title)
  const [loader, setLoader] = useState(false)

  const handleUpdate = async (e) => {
    e.preventDefault()
    setLoader(true)

    await updateCopyTraderProfile(data?._id, {
      email,
      name,
      imgUrl,
      winRate,
      profit,
      roi,
      title: expertise,
    })
      .then((res) => {
        // console.log(res)
        toast.success('updated successfully')
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message)
      })
      .finally(() => {
        setLoader(false)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <p>Update a Copy Trader</p>

        <p
          style={{
            color: 'red',
            cursor: 'pointer',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            gap: '0.2rem',
          }}
          onClick={() => setUpdateToggle(false)}
        >
          <IoMdArrowRoundBack />
          Return
        </p>
      </div>
      <div className="cpt__divider"></div>
      <br />
      <form onSubmit={handleUpdate} className="cpt__form">
        <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
          <label htmlFor="">Email</label>
          <input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            value={email}
            type="email"
            required
            placeholder="Copy Trader Email"
          />
        </div>

        <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
          <label htmlFor="">Name: </label>
          <input
            onChange={(e) => {
              setName(e.target.value)
            }}
            value={name}
            type="text"
            required
            placeholder="Copy Trader Name"
          />
        </div>

        <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
          <label htmlFor="">imgUrl: </label>
          <input
            onChange={(e) => {
              setImgUrl(e.target.value)
            }}
            value={imgUrl}
            type="text"
            required
            placeholder="Copy Image URL"
          />
        </div>

        <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
          <label htmlFor="">winRate: </label>
          <input
            onChange={(e) => {
              setWinRate(e.target.value)
            }}
            value={winRate}
            type="text"
            placeholder="Win Rate"
          />
        </div>

        <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
          <label htmlFor="">ROI: </label>
          <input
            onChange={(e) => {
              setRoi(e.target.value)
            }}
            value={roi}
            type="text"
            placeholder="ROI"
          />
        </div>

        <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
          <label htmlFor="">Profit: </label>
          <input
            onChange={(e) => {
              setProfit(e.target.value)
            }}
            value={profit}
            type="text"
            placeholder="Total Profit"
          />
        </div>

        <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
          <label htmlFor="">Title: </label>
          <input
            onChange={(e) => {
              setExpertise(e.target.value)
            }}
            value={expertise}
            type="text"
            placeholder="Trader Expertise"
          />
        </div>
        <button type="submit" disabled={loader}>
          {loader ? <BeatLoader color="#fff " size={10} /> : 'Update'}
        </button>
      </form>
    </div>
  )
}
