import React, { useMemo, useState, useRef } from 'react'
import coins from '../data/coin-list.json'

import './CoinList.css'
import { nanoid } from 'nanoid'

const CoinList = ({ label, setCoin, currCoin }) => {
  const [search, setSearch] = useState('')
  const ref = useRef(null)
  const coinOptions = useMemo(() => {
    if (search) {
      return coins.coins.filter((coin) =>
        coin.toLowerCase().includes(search.toLowerCase()),
      )
    }
    return coins.coins
  }, [search])

  return (
    <div className="coinlist_field">
      <div>
        <label>{label || 'Crypto Currency'}</label>
      </div>
      <div className="coinlist_input_wrapper">
        <input
          type="text"
          className="coinlist_input"
          placeholder="Select a currency"
          readOnly={true}
          value={currCoin}
        />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>

        <div className="coinlist_list_wrapper" tabIndex={0} ref={ref}>
          <input
            type="search"
            placeholder="Search For Coin..."
            className="coinlist_list_search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="coinlist_list">
            {coinOptions.map((coin) => (
              <div
                key={nanoid()}
                className="coinlist_list_coin"
                onClick={() => {
                  ref.current?.blur()
                  setCoin(coin)
                }}
              >
                {' '}
                {coin}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoinList
