import '../Withdrawal.css'
import { TfiClose } from 'react-icons/tfi'

const NoWithdrawal = ({ close }) => {
  return (
    <div className="withdrawal_con">
      <div className="withdraw_card">
        <div className="header">
          <div className="header_text">Ask a withdraw</div>
          <TfiClose className="close_btn" onClick={() => close()} />
        </div>

        <div className="withdraw_section">
          <div className="withdraw_section_text">
            You need to have an account for at least 7 days to make a withdrawal
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoWithdrawal
