import './Admin.css'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import EditUser from '../../components/EditUser/Overview'
import { nanoid } from 'nanoid'
import client from '../../client'
import axios from 'axios'

const Admin = ({ showTransferModal }) => {
  const [u1, setU1] = useState(false)
  const [clientEmail, setClientEmail] = useState('')
  const [files, setFiles] = useState([])

  const { getAllUser, allUsers, loader } = useContext(UserContext)

  useEffect(() => {
    if (!allUsers) {
      getAllUser()
      // console.log(coins);
    }
  }, [allUsers, getAllUser])

  const handlePop = (email) => {
    setClientEmail(email)
    setU1(true)
  }

  useEffect(() => {
    const fetchAllPayment = async () => {
      try {
        const result = await client.get('/pent/admin/paymentProof')
        setFiles(result.data)
      } catch (err) {
        console.log('error occurred', err)
      }
    }

    fetchAllPayment()
  }, [showTransferModal])

  return (
    <div className="adm_coin">
      {loader ? (
        <div className="loader__con">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="admin_container">
          <div className="admin__con">
            <h1>Admin Center</h1>
            <table style={{ width: '100%', height: '100%' }}>
              <thead>
                <tr className="adm_cell_hd ">
                  <th className="adm_cell_hd">Full Name</th>
                  <th className="adm_cell_hd">Email</th>
                  <th className="adm_cell_hd">Currency</th>
                  <th className="adm_cell_hd">Balance</th>
                  <th className="adm_cell_hd">Created At</th>
                </tr>
              </thead>
              <tbody style={{ width: '100%', height: '100%' }}>
                {allUsers?.map((user) => {
                  if (!user?.admin)
                    return (
                      <tr
                        key={nanoid()}
                        className="adm_col"
                        onClick={() => handlePop(user?.email)}
                      >
                        <td className="adm_cell">
                          <p>{user?.fullName}</p>
                        </td>
                        <td className="adm_cell">
                          <p>{user?.email}</p>
                        </td>

                        <td className="adm_cell">
                          <p>{user?.currency}</p>
                        </td>
                        <td className="adm_cell">
                          <p>${user?.amount}</p>
                        </td>
                        <td className="adm_cell">
                          <p>
                            {new Date(user?.createdAt).toLocaleDateString()}
                          </p>
                        </td>
                      </tr>
                    )
                  return null
                })}
              </tbody>
              {u1 && <EditUser setU1={setU1} userEmail={clientEmail} />}
            </table>
          </div>

          <div className="payment_con">
            <h1>Payment Proofs</h1>
            <div className="file_item_con">
              {files.map((file) => (
                <div
                  key={file.id}
                  className="file-item"
                  onClick={() => (window.location = file.secureUrl)}
                >
                  {`${file.secureUrl} by ${file?.user?.email}`}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Admin
