import React from 'react'
import Heroarea from '../../components/About/Heroarea/Heroarea'
import './About.css'
import AboutCards from '../../components/About/AboutCards/AboutCards'

const About = () => {
  return (
    <>
      <Heroarea />
      <AboutCards />
    </>
  )
}

export default About
