import './Withdraw_wallets.css'
import paypal from '../../../assets/paypal.svg'
import banktransfer from '../../../assets/banktransfer.svg'
import cryptocurrency from '../../../assets/cryptocurrencies.svg'
import { useContext, useEffect, useState } from 'react'
import client from '../../../client'
import { UserContext } from '../../../context/UserContext'
import { useCookies } from 'react-cookie'
import CoinList from '../../CoinList'
import SuccessModal from '../../modals/Success'
import ErrorModal from '../../modals/Error'

const Withdraw_wallets = () => {
  const { user, getDashboard, dash, setDash } = useContext(UserContext)

  const [cookies] = useCookies(['user'])
  const [walAddr, setWalAddr] = useState('')
  const [coinType, setCoinType] = useState('')
  const [network, setNetwork] = useState('')
  const [isUpdateBankSuccess, setIsUpdateBankSuccess] = useState(false)
  const [isUpdateBankError, setIsUpdateBankError] = useState(false)
  const [isUpdateCryptoAddrSuccess, setIsUpdateCryptoAddrSuccess] =
    useState(false)
  const [isUpdateCryptoAddrError, setIsUpdateCryptoAddrError] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      if (!dash) {
        if (cookies) {
          if (cookies?.userToken) {
            await getDashboard()
          }
        } else {
          setDash(null)
        }
      }
    }

    checkAuth()
  }, [cookies, getDashboard, dash, setDash])

  const [w1, setW1] = useState(false)
  const [w2, setW2] = useState(false)
  const [w3, setW3] = useState(false)
  const [, setW4] = useState(true)

  const [bank, setBank] = useState('')
  const [bank_addr, setBank_addr] = useState('')
  const [bank_city, setBank_city] = useState('')
  const [bank_country, setBank_country] = useState('')
  const [bank_acctNum, setBank_acctNum] = useState('')
  const [bank_code, setBank_code] = useState('')

  const updateBank = async (e) => {
    e.preventDefault()
    await client
      .put(
        '/pent/user/updateBank/',
        {
          email: user?.email,
          bankDetails: {
            bank: bank ? bank : dash?.bank,
            bank_addr: bank_addr ? bank_addr : dash?.bank_addr,
            bank_city: bank_city ? bank_city : dash?.bank_city,
            bank_country: bank_country ? bank_country : dash?.bank_country,
            bank_acctNum: bank_acctNum ? bank_acctNum : dash?.bank_acctNum,
            bank_code: bank_code ? bank_code : dash?.bank_code,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.userToken}`,
          },
        },
      )
      .then((res) => {
        setIsUpdateBankSuccess(true)
      })
      .catch((err) => {
        setIsUpdateBankError(true)
      })

    console.log('Form data')
  }

  const addWallet = async (e) => {
    e.preventDefault()
    await client
      .put(
        '/pent/user/addWalletAddress/',
        {
          email: user?.email,
          walletAddress: {
            coin: coinType,
            addr: walAddr,
            network: network,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cookies.userToken}`,
          },
        },
      )
      .then((res) => {
        setIsUpdateCryptoAddrSuccess(true)
      })
      .catch((err) => {
        setIsUpdateCryptoAddrError(true)
      })

    console.log('Form data')
  }

  const handleW1 = () => {
    setW1(true)
    setW2(false)
    setW3(false)
    setW4(false)
  }
  const handleW2 = () => {
    setW1(false)
    setW2(true)
    setW3(false)
    setW4(false)
  }
  const handleW3 = () => {
    setW1(false)
    setW2(false)
    setW3(true)
    setW4(false)
  }

  const handleW4 = () => {
    setW1(false)
    setW2(false)
    setW3(false)
    setW4(true)
  }

  return (
    <>
      <div className="cardboard_con">
        <div className="cardboard" onClick={handleW1}>
          <img src={paypal} alt="" />
        </div>

        <div className="cardboard" onClick={handleW2}>
          <img src={banktransfer} alt="" />
        </div>

        <div className="cardboard" onClick={handleW3}>
          <img src={cryptocurrency} alt="" />
        </div>

        {w1 && (
          <div className="popup_con">
            <div className="generic_cardboard">
              <img src={paypal} alt="" />

              <form>
                <label for="paypal_email">paypal email</label>
                <input
                  type="email"
                  id="paypal_email"
                  placeholder="Paypal email"
                />

                <div className="popup_btns">
                  <div className="cancel_btn" onClick={handleW4}>
                    Cancel
                  </div>

                  <input type="submit" value="Next" />
                </div>
              </form>
            </div>
          </div>
        )}

        {w2 && (
          <div className="popup_con">
            <div className="bank_cardboard">
              <img src={banktransfer} alt="" />

              <form onSubmit={updateBank}>
                <label for="bank_name">bank name</label>
                <input
                  value={bank}
                  type="text"
                  onChange={(e) => setBank(e.target.value)}
                  id="bank_name"
                  placeholder="Bank name"
                />

                <div className="bank_subcon">
                  <div className="subcon_left">
                    <label for="bank_addr">bank address</label>
                    <input
                      type="text"
                      value={bank_addr}
                      onChange={(e) => setBank_addr(e.target.value)}
                      id="bank_addr"
                      placeholder="Bank address"
                    />

                    <label for="bank_coun">bank country</label>
                    <input
                      type="text"
                      value={bank_country}
                      onChange={(e) => setBank_country(e.target.value)}
                      id="bank_coun"
                      placeholder="Bank country"
                    />

                    <label for="swift_code">swift code</label>
                    <input
                      type="text"
                      value={bank_code}
                      onChange={(e) => setBank_code(e.target.value)}
                      id="swift_code"
                      placeholder="Swift code"
                    />

                    <label for="your_addr">your address</label>
                    <input
                      type="text"
                      id="your_addr"
                      placeholder="Your address"
                    />
                  </div>

                  <div className="subcon_right">
                    <label for="bank_city">bank city</label>
                    <input
                      value={bank_city}
                      onChange={(e) => setBank_city(e.target.value)}
                      type="text"
                      id="bank_city"
                      placeholder="Bank city"
                    />

                    <label for="bank_acc">bank account number / iban</label>
                    <input
                      type="text"
                      value={bank_acctNum}
                      onChange={(e) => setBank_acctNum(e.target.value)}
                      id="bank_acc"
                      placeholder="Bank name"
                    />

                    <label for="first_last">your first and last name</label>
                    <input
                      type="text"
                      id="first_last"
                      placeholder="Your first and last name"
                    />

                    <label for="your_country">your country</label>
                    <input
                      type="text"
                      id="your_coun"
                      placeholder="Your country"
                    />
                  </div>
                </div>

                <label for="yourcity">Your city</label>
                <input type="text" id="yourcity" placeholder="Your city" />

                <div className="popup_btns">
                  <div className="cancel_btn" onClick={handleW4}>
                    Cancel
                  </div>

                  <input type="submit" value="Next" />
                </div>
              </form>
            </div>
          </div>
        )}

        {w3 && (
          <div className="popup_con">
            <div className="generic_cardboard">
              <img src={cryptocurrency} alt="" />

              <form onSubmit={addWallet}>
                <div style={{ marginBottom: '1rem' }}>
                  <CoinList
                    label="Crypto Currency Name"
                    setCoin={setCoinType}
                    currCoin={coinType}
                  />
                </div>

                <div>
                  <label for="address">address</label>
                  <input
                    type="text"
                    id="address"
                    onChange={(e) => setWalAddr(e.target.value)}
                    placeholder="Address"
                    value={walAddr}
                  />
                </div>

                <div>
                  <label for="address">Network</label>
                  <input
                    type="text"
                    id="network"
                    onChange={(e) => setNetwork(e.target.value)}
                    placeholder="Network"
                    value={network}
                  />
                </div>

                <div className="popup_btns">
                  <div className="cancel_btn" onClick={handleW4}>
                    Cancel
                  </div>

                  <input type="submit" value="Next" />
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      {isUpdateBankSuccess && (
        <div className="popup_con">
          <SuccessModal
            message="Hurray!!, your bank details have been successfully updated"
            onClickPrimaryBtn={() => {
              handleW4()
              setIsUpdateBankSuccess(false)
            }}
            primaryBtnText="Complete"
          />
        </div>
      )}
      {isUpdateBankError && (
        <div className="popup_con">
          <ErrorModal
            message="Oops, An error occurred while updating your bank details"
            primaryBtnText="Try again"
            onClickPrimaryBtn={() => setIsUpdateBankError(false)}
            onClose={handleW4}
          />
        </div>
      )}

      {isUpdateCryptoAddrSuccess && (
        <div className="popup_con">
          <SuccessModal
            message="Hurray!!, your crypto details have been successfully updated"
            onClickPrimaryBtn={() => {
              handleW4()
              setIsUpdateCryptoAddrSuccess(false)
            }}
            primaryBtnText="Complete"
          />
        </div>
      )}
      {isUpdateCryptoAddrError && (
        <div className="popup_con">
          <ErrorModal
            message="Oops, An error occurred while updating your crypto wallet details"
            primaryBtnText="Try again"
            onClickPrimaryBtn={() => setIsUpdateCryptoAddrError(false)}
            onClose={handleW4}
          />
        </div>
      )}
    </>
  )
}

export default Withdraw_wallets
