import React, { useEffect, useState } from 'react'
import { MdDelete, MdKeyboardArrowRight } from 'react-icons/md'
import { IoMdClose } from 'react-icons/io'
import { AiFillEdit, AiFillPlusCircle } from 'react-icons/ai'
import UpdateCopyForm from './Form/UpdateCopyForm'
import AddCopyForm from './Form/AddCopyForm'
import {
  deleteCopyTraderProfile,
  getAllCopyTraderProfiles,
} from '../../../utils/copytrader'
import toast from 'react-hot-toast'

export default function CopyTradingAdmin() {
  const [updateToggle, setUpdateToggle] = useState(false)
  const [traderData, setTraderData] = useState([])
  const [uptData, setUptData] = useState(null)

  const getTraders = async () => {
    const res = await getAllCopyTraderProfiles()

    setTraderData(res)

    // console.log(res)
  }

  useEffect(() => {
    getTraders()
  }, [traderData])

  const handleDelete = async (item) => {
    await deleteCopyTraderProfile(item?._id)
      .then((res) => {
        console.log(res)
        toast.success(`deleted ${item?.name}`)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message)
      })
  }

  return (
    <div className="cpt__con">
      <div className="cpt__header">
        <p style={{ color: '#29c368' }}>Dashboard</p>
        <MdKeyboardArrowRight size={22} />
        <p>Admin</p>
        <MdKeyboardArrowRight size={22} />
        <p>Traders</p>
      </div>

      {/* main */}

      <div className="cpt__main">
        {/* left side */}
        <div className="cpt__trader__con">
          {/* add trader form */}
          {updateToggle ? (
            <UpdateCopyForm data={uptData} setUpdateToggle={setUpdateToggle} />
          ) : (
            <AddCopyForm />
          )}
        </div>

        {/* right side */}

        <div className="cpt__copytrader__con">
          {/* card */}

          {traderData?.map((item, index) => (
            <div key={index} className="cpt__copytrader__card">
              <img src={item?.imgUrl} alt="" />

              <div className="cpt__copytrader__text">
                <h3>{item?.name}</h3>
                <p>Win Rate: {item?.winRate}%</p>
                <p>ROI: {item?.roi}%</p>

                <p>
                  Profit:{' '}
                  <span style={{ color: 'black', fontWeight: 'bold' }}>
                    {item?.profit.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </span>
                </p>

                <p>{item?.title}</p>
              </div>

              <AiFillEdit
                onClick={() => {
                  setUptData(item)
                  setUpdateToggle(true)
                }}
                style={{ cursor: 'pointer', color: '#36a6da' }}
                size={25}
              />

              <MdDelete
                onClick={() => handleDelete(item)}
                style={{ cursor: 'pointer', color: 'red' }}
                size={25}
              />
            </div>
          ))}

          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  )
}
